<template>
  <div>
    <div class="detail-list">
      <!-- v-if="!toogle && deviceList.length && currentList.length" -->
      <div class="detail-list-item">
        <span class="detail-list-item-label">用户名称：</span>
        <span class="detail-list-item-value">{{
          (deviceList && deviceList.userName) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">设备名称：</span>
        <span class="detail-list-item-value">{{
          (deviceList && deviceList.deviceName) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">挂载名称：</span>
        <span
          v-if="
            deviceList &&
            deviceList.mountNameList &&
            deviceList.mountNameList.length > 0
          "
        >
          <span
            class="detail-list-item-value"
            v-for="(item, index) in deviceList.mountNameList"
            :key="index"
            ><span>{{ item }}、</span></span
          >
        </span>
        <span class="detail-list-item-value" v-else>暂无</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">使用电池：</span>
        <span class="detail-list-item-value">{{
          (deviceList && deviceList.batteryNumbers) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item" style="margin-bottom: 30px">
        <span class="detail-list-item-label">数据链路：</span>
        <span
          class="detail-list-item-value"
          v-if="deviceList && deviceList.stationType === 0"
          >普通地面站</span
        >
        <span
          class="detail-list-item-value"
          v-else-if="deviceList && deviceList.stationType === 1"
          >飞控中心</span
        >
        <span class="detail-list-item-value" v-else>暂无</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">经度：</span>
        <span class="detail-list-item-value"
          >{{ (currentList && currentList.longitude) || "暂无" }}
        </span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">纬度：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.latitude) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">绝对高度：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.altitude) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">速度：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.groundSpeed) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">爬升率：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.climbRate) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">偏航角度：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.yaw) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">云台俯仰角度：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.pitch) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">飞行距离：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.flightDistance) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">飞行时长(s)：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.flightTime) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">飞行高度：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.height) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">当前电压：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.voltage) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">信号值：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.satCount) || "暂无"
        }}</span>
      </div>
      <div class="detail-list-item">
        <span class="detail-list-item-label">无人机状态：</span>
        <span class="detail-list-item-value">{{
          (currentList && currentList.push_status) || "暂无"
        }}</span>
      </div>
    </div>

    <div class="export-btn-box" v-if="!toogle">
      <!-- v-if="deviceList.length && currentList.length" -->
      <div class="export-btn">日志导出</div>
      <div class="export-btn marr0">视频导出</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentList: {
      type: Object,
      default: () => {},
    },
    deviceList: {
      type: Object,
      default: () => {},
    },
    toogle: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    
  },
};
</script>

<style lang="scss" scoped>
.detail-list {
  margin-left: 20px;
  .detail-list-item {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bbcff0;
    line-height: 22px;
    margin-bottom: 15px;
  }
}
.export-btn-box {
  margin-top: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  .export-btn {
    width: 100px;
    height: 32px;
    background: linear-gradient(
      180deg,
      rgba(28, 91, 197, 0.69) 0%,
      #091a38 100%
    );
    opacity: 0.8;
    border: 1px solid #488cff;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #9fc4ff;
    line-height: 32px;
    text-align: center;
    margin-right: 34px;
  }
  .marr0 {
    margin-right: 0px;
  }
}
</style>>
