<template>
  <div class="page-video">
    <div class="video-box">
      <LivePlayer
        ref="video"
        :videoUrl="videoUrl"
        :muted="false"
        :controls="isShowControls"
        :show-custom-button="false"
        :hide-snapshot-button="false"
        :currentTime="currentTime"
        :playback-rate="speed"
        @ended="onVideoEnd"
        @timeupdate="onVideoTimeUpdate"
        @pause="handlePause"
        @play="handlePlay"
        style="margin: 0 auto; max-width: 1084px"
        :loading.sync="loading"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-background="#000"
      ></LivePlayer>
    </div>
  </div>
</template>

<script>
import LivePlayer from "@liveqing/liveplayer";
import moment from "moment";
export default {
  name: "task-video",
  components: {
    LivePlayer,
  },
  props: {
    videoUrl: {
      type: String,
      default: () => false,
    },
    currentTime: {
      type: String | Number,
    },
    isShowControls: {
      type: Boolean,
      default: () => false
    },
    play: {
      type: Boolean,
    },
    changeTime: {
      type: Number,
    },
    speed: {
      type: Number,
    }
  },
  data() {
    return {
      videos: [
        // {
        //   duration: 180, // 视频时长多少秒
        //   hls: "http://39.99.144.107:9000/haining/20220601/264b290f-02b9-4d4a-a7a9-2bbba570bbbd.mp4", // 视频url
        //   startAt: "20220625093000", // 视频开始时间
        // },
      ],
      loading: false,
      name: "",
      timing: "",
      changeSpeed: '1x',
    };
  },

  watch: {
    "play": {
      handler (value) {
        if (value) {
          this.$refs.video.play()
        } else {
          this.$refs.video.pause()
        }
        
      },
      deep: true,
      // immediate: true
    },
    "changeTime": {
      handler (value) {
        if (value) {
          this.currentTime = value
        }
      },
      deep: true,
      immediate: true
    },
    "timing": {
      handler (value, oldValue) {
        if (value - oldValue > 2) {
          this.$emit('isChangeVideoTime', parseInt(value))
        } else if (oldValue - value > 2) {
          this.$emit('isChangeVideoTime', parseInt(value))
        }
      },
      deep: true,
      immediate: true
    },
    "changeSpeed": {
      handler (value, oldValue) {
        if (value != oldValue) {
          var basic = value.substr(0, value.length - 1); 
          this.$emit('isChangeSpeed', basic)
        }
      },
      deep: true,
      immediate: true
    },
    "speed": {
      handler (value) {
        if (value) {
          this.$refs.video.playbackRate_ = value 
        }
      },
      deep: true,
      // immediate: true
    },
  },
  computed: {
    videoUrls() {
      return this.videos.map((val, index, array) => {
        return (val || {}).hls;
      });
    },
  },
  methods: {

    // 播放结束
    onVideoEnd() {
      var idx = this.videoUrls.indexOf(this.videoUrl);
      if (idx >= 0 && idx < this.videos.length - 1) {
        var nextVideo = this.videos[idx + 1];
        if (!nextVideo) return;
        var startTime = moment(nextVideo.startAt, "YYYYMMDDHHmmss");
        var n = startTime.diff(startTime.clone().startOf("day"), "minutes");
        this.$refs["timeRule"].clickMinute(n);
      }
    },
    // 播放中
    onVideoTimeUpdate(currentTime) {
      this.timing = currentTime
      var a = document.getElementsByClassName("vjs-playback-rate-value")
      this.changeSpeed = a[0].textContent
      var idx = this.videoUrls.indexOf(this.videoUrl);
      if (idx >= 0 && idx < this.videos.length) {
        var video = this.videos[idx];
        var startTime = moment(video.startAt, "YYYYMMDDHHmmss");
        startTime.add(currentTime, "seconds");
        var n = startTime.diff(startTime.clone().startOf("day"), "minutes");
        this.$refs["timeRule"].clickMinute(n, false);
      }
    },
    // 暂停
    handlePause(e) {
      this.$emit('play', false)
      console.log(e);
    },
    // 播放
    handlePlay(e) {
      this.$emit('play', true)
       
      console.log(8989);
    },
    
    onTimeChange(video) {
      this.videoUrl = (video || {}).hls || "";
      this.currentTime = (video || {}).currentTime || 0;
    },
  },
  mounted() {
   
    // console.log(123456,this.$refs.video.play());
  },
	

};
</script>

<style lang="scss" scoped>
.page-video {
  width: 100%;
  height: 100%;
  // margin-top: 50px;
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: center;
  position: relative;
  .video-box {
    // width: 1084px;
    // height: 720px;
    width: 100%;
     height: 100%;
    margin: 0 auto;
    z-index: 1;
  }
  .time-rule-box {
    width: 1084px;
    height: 50px;
    position: absolute;
    top: 640px;
    left: 0;
  }
}
</style>