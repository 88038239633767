<template>
  <div class="fly-log">
    <div class="main">
      <div class="select">
        <div class="select-one">
          <el-cascader
            class="input-orgname"
            size="mini"
            placeholder="请选择所属单位"
            v-model="organId"
            :options="selectList"
            :show-all-levels="false"
            :props="{
              children: 'child',
              label: 'name',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
              empty: false,
            }"
          >
          </el-cascader>
        </div>
        <div class="select-one">
          <el-select v-model="type" placeholder="请选择设备" size="mini">
            <el-option label="无人机" :value="1">无人机</el-option>
            <el-option label="地面站" :value="2">地面站</el-option>
            <el-option label="挂载" :value="3">挂载</el-option>
            <el-option label="车辆" :value="4">车辆</el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="box-left">
          <div class="box-left-item">
            <div
              class="auv-box"
              v-for="(item, index) in leftList"
              :key="item.id"
            >
              <div class="title">{{ item.deviceName }}</div>
              <img
                :class="
                  index === imgIndex ? 'auv-box-img-active' : 'auv-box-img'
                "
                src="../../../assets/flylog/auv.png"
                @click="handleChangeDevice(item, index)"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="box-right">
          <div class="video-box">
            <div class="video-date">
              <div style="display: flex">
                <div class="day-week-month">
                <div
                  :class="dayType === 'day' ? 'day-btn-active' : 'day-btn'"
                  @click="changeDate('day')"
                >
                  本日
                </div>
                <div
                  :class="dayType === 'week' ? 'day-btn-active' : 'day-btn'"
                  @click="changeDate('week')"
                >
                  本周
                </div>
                <div
                  :class="dayType === 'month' ? 'day-btn-active' : 'day-btn'"
                  @click="changeDate('month')"
                >
                  本月
                </div>
              </div>
              <div class="block">
                <el-date-picker
                  v-model="timeDate"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  size="small"
                  type="datetimerange"
                  range-separator="—"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              
              </div>
              <HorizonTimeLine :timelineData="timelineData"  v-if="timelineData && showBox === 1" :active="active" @upactive="value => active = value"/>
              <!-- <Linecom :timelineData="timelineData" :activeYear="2021"  v-if="timelineData" /> -->
            </div>
            <div
              v-if="showBox === 1"
              :class="[toogle === true ? 'big-box' : 'small-box']"
            >
              <!-- 
                videoUrl: 视频的url
                currentTime: 当前时间
                isShowControls：小窗的时候不让展示底部控制件
                play: 控制视频播放和暂停
                changeTime2: 拖动进度条后的时间
                speed2: 改变倍速
                handlePlay: 控制视频播放和暂停的方法
                handleChangeVideoTime: 视频拉动进度条得到的时间传出来的方法
                handleChangeVideoSpeed: 视频改变倍速传出来的方法
               -->
              <TaskVideo
                v-if="deviceList"
                :videoUrl="videoUrl"
                :currentTime="currentTime"
                :isShowControls="toogle"
                :play="play"
                :changeTime="changeTime2"
                :speed="speed2"
                @play="handlePlay"
                @isChangeVideoTime="handleChangeVideoTime"
                @isChangeSpeed="handleChangeVideoSpeed"
              />
              <div v-if="toogle" class="toogle-buttom" @click="handleToogle()">
                切换
              </div>
            </div>
            <div
              v-if="showBox === 1"
              :class="[toogle === true ? 'small-box' : 'big-box']"
            >
              <!-- 
                list: 地图轨迹数据
                isShow: 控制是否展示播放按钮和倍速选择
                isShowFooter: 小窗的时候不让展示底部控制件
                play: 控制视频播放和暂停
                changeTime1: 拖动进度条后的时间
                speed1: 改变倍速
                handlePlay: 控制视频播放和暂停的方法
                handleChangeMapTime: 轨迹拉动进度条得到的时间传出来的方法
                handleChangeMapSpeed: 轨迹改变倍速传出来的方法
                handleGetCurrentList: 获取轨迹播放时的右侧设备的数据
               -->
              <Map
                :recordList="list"
                :isShow="isShow"
                :isShowFooter="toogle"
                :play="play"
                :changeTime="changeTime1"
                :speed="speed1"
                :track="track"
                @play="handlePlay"
                @isChangeMapTime="handleChangeMapTime"
                @isChangeMapSpeed="handleChangeMapSpeed"
                @currentList="handleGetCurrentList"
              />
              <div v-if="!toogle" class="toogle-buttom" @click="handleToogle()">
                切换
              </div>
            </div>

            <!-- 时间刻度尺 -->
            <div v-if="showBox === 1" class="time-rule-box">
              <!-- 
                videos: 时间刻度尺视频数据
                timeChange: 控制时间刻度尺滑动变化的方法
               -->
              <CloudRecordTimeRule
                :videos="videos"
                @timeChange="onTimeChange"
                ref="timeRule"
                v-loading="loadingRecords"
              />
            </div>
          </div>




          <div v-if="showBox === 1" class="img-log">
            <div class="export-select">
              <div class="show">
                <el-select v-model="showBox" placeholder="默认显示" size="mini">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-show="toogle" class="img-export" @click="exportImg">图片导出</div>
            </div>
            <div v-if="toogle && deviceList && deviceList.images && deviceList.images.length > 0" class="img-detail-list">
              <div
                class="img-detail"
                v-for="(item, index) in deviceList.images"
                :key="index"
              >
                <div class="img">
                  <img class="img" :src="item" alt="" />
                </div>
              </div>
            </div>

            <TableList 
            :deviceList="deviceList"
            :currentList="currentList"
            :toogle="toogle"
            v-if="!toogle && list"
            />
          </div>

          <div v-if="showBox === 2" class="statistical-mode">
             <div class="statistical-mode-select">
              <div class="show">
                <el-select v-model="showBox" placeholder="默认显示" size="mini">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <ImgList 
            :countList="countList"
            :optionsTask="optionsTask"
            :tableData="tableData"
            :taskName="taskName"
            :taskList="taskList"
            @untaskName="value => taskName = value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import dayjs from "dayjs";
import Map from "./map.vue";
import TaskVideo from "./tasks-video.vue";
import CloudRecordTimeRule from "./CloudRecordTimeRule.vue";
import TableList from "./rightCom/tableList.vue"
import ImgList from "./rightCom/imgList.vue"
import HorizonTimeLine  from './HorizonTimeLine.vue'
import { mapGetters, mapActions } from "vuex";
import { packageImages }  from '@/utils/unti.js'
import API from "@/api";
export default {
  computed: {
    ...mapGetters(["dbox_data"]),
  },

  components: { Map, TaskVideo, CloudRecordTimeRule,TableList, ImgList, HorizonTimeLine },
  data() {
    return {
      organId: 1,
      selectList: [],
      type: 1,
      leftList: [],
      dayType: "day",
      currentList: {},
      deviceList: {},
      imgIndex: "",
      deviceHardId: "",
      countList: {},
      tableData: [],
      options: [
        {
          value: 1,
          label: "默认显示",
        },
        {
          value: 2,
          label: "统计模式",
        },
      ],
      showBox: 1, // 右上角显示模式切换, 默认为1
      timeDate: [
        dayjs().format("YYYY-MM-DD 00:00:00"),
        dayjs().format("YYYY-MM-DD HH:mm:ss"),
      ],
      list: [],
      videos: [
        {
          duration: "", // 视频时长多少秒
          hls: "", // 视频url
          startAt: "", // 视频开始时间
        },
      ],
      isShow: false,
      loadingRecords: false,
      videoUrl: "",
      currentTime: 0,//视频播放时间
      toogle: false, // 视频与地图切换，默认fasle为地图
      play: false,
      changeTime1: null,  // 时间进度条时间
      changeTime2: null, //轨迹进度条时间
      speed1: 1,
      speed2: 1,
      optionsTask: [
        {
        label: '任务1',
        value: 1
      },
      {
        label: '任务2',
        value: 2
      }
      ],
      timelineData: null,//时间轴显示数据
      active: null, // 时间轴选中状态
      taskName: null, // 统计页面任务表格下拉框选中状态
      taskList: null, // 统计页面任务表格下拉框数据
      track: false,
      
    };
  },
  watch: {
    timeDate: {
      handler(value) {
        if (value) {
          if (this.deviceHardId) {
            if(this.showBox === 1) {
        this.getDeviceInfo();
      } else {
        this.getFlightStatisticsIco()
        this.getFlightStatisticsList()
        this.taskNameList()
      }
            
          }
        }
      },
    },
    showBox: function(newVal, oldVal) {
      if(newVal != oldVal && this.deviceHardId) {
        if (newVal === 1) {
          this.getDeviceInfo();
        } else {
          this.getFlightStatisticsIco()
          this.getFlightStatisticsList()
          this.taskNameList()
        }
      }
    },
    taskName: function(newVal, oldVal) {
      if(newVal != oldVal && this.deviceHardId) {
        this.getFlightStatisticsList()
      }
    }
  },
  methods: {
    getAllOrganization() {
      let data = this.dbox_data;
      this.selectList = this.getTreeData(data);
      this.organId = this.selectList[this.selectList.length - 1].id;
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child && data.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].child && data[i].child.length < 1) {
            this.getTreeData(data[i].child);
          }
        }
      }
      return data;
    },
    // 左侧无人机图片列表
    async getLeftList() {
      try {
        let res = await API.TASK.getTaskLeftList({
          organId: this.organId,
          type: this.type,
        });
        this.leftList = res.data.data;
      } catch (e) {
        console.error(e);
      }
    },
    // 点击选择无人机设备
    handleChangeDevice(info, index) {
      // 判断是否点击了其他设备
      this.list = null
      this.deviceList = null
      this.currentTime = 0
      this.track = false

      if(this.deviceHardId != info.deviceHardId) {
        this.deviceHardId = info.deviceHardId;
        this.imgIndex = index;
        if(this.showBox === 1) {
          this.getDeviceInfo();
        } else {
          this.getFlightStatisticsIco()
          this.getFlightStatisticsList()
        }
      }
      
    },
    // 右侧无人机信息详情和视频信息
    async getDeviceInfo() {
      this.timelineData = null
      try {
        let res = await API.TASK.getDeviceInfoById({
          deviceHardId: this.deviceHardId,
          start: this.timeDate[0],
          end: this.timeDate[1],
        });
        if (res.data.length) {
          this.deviceList = res.data[0]; // 暂定取第一个
          this.videos[0].duration = this.deviceList.videoTime;
          this.videos[0].hls = this.deviceList.url;
          this.videos[0].startAt = this.deviceList.videoStartTime *1 ;
          // this.videos[0].startAt = 20220711093000;

          console.log('this.videos', this.videos);

          this.timelineData =res.data
          this.active = res.data[0].flightSortieId
          this.async_map_list(
            this.deviceList.deviceHardId,
            this.deviceList.taskId
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
    // 地图轨迹的信息
    async async_map_list(deviceHardId, taskId) {
      try {
        let res = await API.TASK.getMapData({
          deviceHardId: deviceHardId,
          taskId: taskId,
        });
        if(res.status === 1) {
          if(res.data.length > 0) {
            this.list = res.data;
            this.track = true
          } else {
            this.$message.warning('暂无轨迹信息')
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        console.error(e);
      }
    },
    // 本日、本周、本月切换
    changeDate(type) {
      this.dayType = type;
      let start_time,
        end_time = dayjs().format("YYYY-MM-DD HH:mm:ss");
      if (type === "day") {
        start_time = dayjs().format("YYYY-MM-DD 00:00:00");
        this.timeDate = [start_time, end_time];
      } else if (type === "week") {
        start_time = dayjs().subtract(7, "day").format("YYYY-MM-DD 00:00:00");
        this.timeDate = [start_time, end_time];
      } else {
        start_time = dayjs().subtract(30, "day").format("YYYY-MM-DD 00:00:00");
        this.timeDate = [start_time, end_time];
      }
    },

    // 统计模式 - 上边图标统计
    async getFlightStatisticsIco() {
      try {
        let res = await API.TASK.flightStatisticsIco({
          deviceHardId: this.deviceHardId,
          start: this.timeDate[0],
          end: this.timeDate[1],
        });
        this.countList = res.data
      } catch (e) {
        console.error(e);
      }
    },

    // 统计模式 - 表格数据
    async getFlightStatisticsList() {
      try {
        let res = await API.TASK.flightStatisticsList({
          deviceHardId: this.deviceHardId,
          start: this.timeDate[0],
          end: this.timeDate[1],
          taskName: this.taskName,
        });
        this.tableData = res.data.data;

      } catch (e) {
        console.error(e);
      }
    },

    // 统计模式 - 表格数据任务名称下拉框
    async taskNameList() {
      try {
        let res = await API.TASK.taskNameList({
          deviceHardId: this.deviceHardId,
          start: this.timeDate[0],
          end: this.timeDate[1]
        });
        this.taskList = res.data
      } catch (e) {
        console.error(e);
      }
    },

    onTimeChange(video) {
      if (video) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      this.videoUrl = (video || {}).hls || "";
      this.currentTime = (video || {}).currentTime || 0;
    },
    // 切换
    handleToogle() {
      this.toogle = !this.toogle;
    },
    handlePlay(e) {
      this.play = e;
    },
    // 视频拉动进度条得到的时间
    handleChangeVideoTime(e) {
      this.changeTime1 = Number(e);
    },
    // 轨迹拉动进度条得到的时间
    handleChangeMapTime(e) {
      this.changeTime2 = Number(e);
    },
    // 视频改变倍速
    handleChangeVideoSpeed(e) {
      this.speed1 = Number(e);
    },
    // 轨迹改变倍速
    handleChangeMapSpeed(e) {
      this.speed2 = Number(e);
    },
    // 获取播放设备数据
    handleGetCurrentList(e) {
      this.currentList = e;
    },
    // 图片导出
    exportImg() {
      packageImages(this.deviceList.images)
    },
  },
  mounted() {
    this.getAllOrganization();
    this.getLeftList();

    let mmt = moment();
    let n = mmt.diff(mmt.clone().startOf("day"), "minutes");
    // n -= 10;
    // if (n < 0) n = 0;
    this.$refs.timeRule.clickMinute(n);
  },
};
</script>

<style lang="scss" scoped>
.fly-log {
  // widows: 100%;
  width: 100%;
  // height: calc(100vh - 197px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .main {
    width: 100%;
    height: 100%;
    padding: 0px 24px;
    box-sizing: border-box;
    .select {
      width: 100%;
      height: 28px;
      display: flex;
      .select-one {
        margin-right: 32px;
      }
      .select-one ::v-deep .el-select {
        background: linear-gradient(
          270deg,
          rgba(0, 154, 255, 0) 0%,
          rgba(0, 154, 255, 0.5) 100%
        );
        border-radius: 2px;
      }
      .select-one ::v-deep .el-input__inner {
        background: linear-gradient(
          270deg,
          rgba(0, 154, 255, 0) 0%,
          rgba(0, 154, 255, 0.5) 100%
        );
        border: none;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        border-left: 2px solid #009aff;
      }
    }
    .content {
      display: flex;
      // justify-content: space-between;
      position: relative;
      //   align-items: flex-start;
      .box-left {
        // padding-top: 20px;
        // width: 382px;
        margin-top: 5px;

        width: 373px !important;
        // height: 100%;
        height: calc(100vh - 127px);

        background: url(~@/assets/flylog/box-left-bg.png) no-repeat;
        background-size: 100% 100%;
        .box-left-item {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          .auv-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 16px;
            .title {
              width: 357px;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 32px;
              background: url(~@/assets/flylog/title-bg.png) no-repeat;
              background-size: 100% 100%;
              text-align: center;
            }
            .auv-box-img-active {
              width: 357px;
              height: 186px;
              border: 1px solid red;
            }
            .auv-box-img {
              width: 357px;
              height: 186px;
              border: 1px solid #009aff;
            }
          }
        }
      }
      .box-right {
        overflow: hidden;
        margin-top: 5px;
        padding: 0px 8px 0px 8px;
        width: 1486px;
        // height: 939px;
        height: 100%;
        height: calc(100vh - 127px);
        display: flex;
        justify-content: space-between;
        // align-items: center;
        background: url(~@/assets/flylog/box-right.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        .video-box {
          .video-date {
            position: absolute;
            top: 20px;
            left: 20px;
            // display: flex;
            .day-week-month {
              cursor: pointer;
              margin-right: 20px;
              display: flex;
              width: 144px;
              height: 28px;
              .day-btn-active {
                width: 45px;
                height: 28px;
                border-radius: 2px;
                border: 1px solid #2c6cef;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #e0e6f2;
                background-color: #2c6cef;
                line-height: 28px;
                text-align: center;
              }
              .day-btn {
                width: 45px;
                height: 28px;
                border-radius: 2px;
                border: 1px solid #2c6cef;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #e0e6f2;
                line-height: 28px;
                text-align: center;
              }
            }
            .block {
              width: 350px;
              height: 28px;
            }
            .block ::v-deep .el-date-editor {
              width: 350px;
              background: rgba(12, 34, 67, 0.5);
              border: 1px solid #009aff;
              .el-range-input {
                color: #ffffff;
              }
              .el-range-separator {
                color: #9fc4ff;
              }
            }
          }
          .steps {
            widows: 100%;
            margin-top: 24px;
            height: 20px;
            ::v-deep .el-step__icon {
              width: 12px;
              height: 12px;
              background: #0fef80;
              border: 1px solid #0fef80;
            }
            ::v-deep .el-step__icon.is-text {
              color: transparent;
            }
            ::v-deep .el-step__line {
              background-color: #45648f;
            }
            ::v-deep .el-step.is-center .el-step__description {
              font-size: 16px;
              font-family: D-DIN, D;
              font-weight: normal;
              color: #9fc4ff;
              line-height: 17px;
            }
          }
          .big-box {
            margin-top: 150px;
            width: 1084px;
            height: 690px;
            position: relative;
            z-index: 3;

            .toogle-buttom {
              width: 50px;
              height: 26px;
              cursor: pointer;
              background-color: #fff;
              border-radius: 5px;
              text-align: center;
              line-height: 26px;
              font-size: 16px;
              color: black;
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 3;
            }
          }
          .time-rule-box {
            width: 1084px;
            height: 50px;
            position: absolute;
            bottom: 35px;
            left: 6px;
            z-index: 3;
          }
          .small-box {
            width: 158px;
            height: 78px;
            background-color: #fff;
            position: absolute;
            top: 156px;
            left: 20px;
            z-index: 4;
          }
        }
        .img-log {
          padding: 0px 8px;
          width: 378px;
          // height: 916px;
          height: 100%;
          background: url(~@/assets/flylog/img-log-bg.png) no-repeat;
          background-size: 100% 100%;
          z-index: 4;
          .export-select {
            .img-export {
              width: 100px;
              height: 32px;
              background: linear-gradient(
                180deg,
                rgba(28, 91, 197, 0.69) 0%,
                #091a38 100%
              );
              opacity: 0.8;
              border: 1px solid #488cff;
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #9fc4ff;
              line-height: 32px;
              text-align: center;
            }
            .show {
              margin-top: 12px;
              margin-left: 230px;
            }
            .show ::v-deep .el-select {
              width: 132px;
              height: 28px;
              background: linear-gradient(
                270deg,
                rgba(0, 154, 255, 0.5) 0%,
                rgba(0, 154, 255, 0) 100%
              );
              border-radius: 2px;
            }
            .show ::v-deep .el-input__inner {
              background: linear-gradient(
                270deg,
                rgba(0, 154, 255, 0.5) 0%,
                rgba(0, 154, 255, 0) 100%
              );
              border: none;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 22px;
              border-right: 2px solid #009aff;
            }
          }
          .img-detail-list {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 24px;
            // width: 362px;
            // height: 748px;
            height: 630px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 4px;
              /*高宽分别对应横竖滚动条的尺寸*/
              height: 1px;
            }

            &::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 3px;
              -webkit-box-shadow: inset 0 0 5px #009aff;
              background: #009aff;
            }

            &::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              background: #031a31;
            }
            .img-detail {
              // margin-top: 24px;
              width: 362px;
              height: 175px;
              background: rgba(0, 58, 96, 0.5);
              border: 1px solid #009aff;
              border-image: linear-gradient(
                  180deg,
                  rgba(0, 154, 255, 0.5),
                  rgba(0, 171, 255, 0.2)
                )
                1 1;
              .img {
                width: 362px;
                // height: 125px;
                height: 175px;
              }
              .date-box {
                margin-bottom: 16px;
                .date-time {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0px 5px;
                  .date {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #bbcff0;
                    line-height: 20px;
                  }
                  .time {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #bbcff0;
                    line-height: 20px;
                  }
                }
              }
            }
          }

          // .detail-list {
          //   margin-left: 20px;
          //   .detail-list-item {
          //     font-size: 16px;
          //     font-family: PingFangSC-Regular, PingFang SC;
          //     font-weight: 400;
          //     color: #bbcff0;
          //     line-height: 22px;
          //     margin-bottom: 15px;
          //   }
          // }
          // .export-btn-box {
          //   margin-top: 29px;
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   .export-btn {
          //     width: 100px;
          //     height: 32px;
          //     background: linear-gradient(
          //       180deg,
          //       rgba(28, 91, 197, 0.69) 0%,
          //       #091a38 100%
          //     );
          //     opacity: 0.8;
          //     border: 1px solid #488cff;
          //     font-size: 14px;
          //     font-family: MicrosoftYaHei;
          //     color: #9fc4ff;
          //     line-height: 32px;
          //     text-align: center;
          //     margin-right: 34px;
          //   }
          //   .marr0 {
          //     margin-right: 0px;
          //   }
          // }
        }
        .statistical-mode {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 92px;
          position: relative;
          .statistical-mode-select {
            position: absolute;
            top: -70px;
            right: 16px;
            .show ::v-deep .el-select {
              width: 132px;
              height: 28px;
              background: linear-gradient(
                270deg,
                rgba(0, 154, 255, 0.5) 0%,
                rgba(0, 154, 255, 0) 100%
              );
              border-radius: 2px;
            }
            .show ::v-deep .el-input__inner {
              background: linear-gradient(
                270deg,
                rgba(0, 154, 255, 0.5) 0%,
                rgba(0, 154, 255, 0) 100%
              );
              border: none;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 22px;
              border-right: 2px solid #009aff;
            }
          }
          // .task-box {
          //   width: 100%;
          //   height: 186px;
          //   // margin-left: 20px;
          //   display: flex;
          //   justify-content: center;
          //   .task {
          //     width: 112px;
          //     height: 186px;
          //     display: flex;
          //     flex-direction: column;
          //     justify-content: center;
          //     align-items: center;
          //     .task-unit {
          //       width: 112px;
          //       height: 112px;
          //       background: url(~@/assets/flylog/unit-bg.png) no-repeat;
          //       background-size: 112px 112px;
          //       text-align: center;
          //       line-height: 112px;
          //       font-family: WeiRuanYaHei-Regular;
          //       font-size: 30px;
          //       color: #4bc5ff;
          //     }
          //     .task-value {
          //       // width: 56px;
          //       height: 30px;
          //       font-size: 30px;
          //       font-family: D-DIN-Bold, D-DIN;
          //       font-weight: bold;
          //       color: #a2c4fd;
          //       line-height: 33px;
          //       text-align: center;
          //       margin-top: 14px;
          //     }
          //     .task-title {
          //       // width: 80px;
          //       height: 22px;
          //       font-size: 16px;
          //       font-family: PingFangSC-Regular, PingFang SC;
          //       font-weight: 400;
          //       color: #6a99e9;
          //       line-height: 22px;
          //       margin-top: 8px;
          //     }
          //   }
          //   .task-line {
          //     width: 12px;
          //     height: 102px;
          //     margin: 0px 64px;
          //     img {
          //       width: 12px;
          //       height: 102px;
          //     }
          //   }
          // }
          // .select-task {
          //   width: 176px;
          //   height: 36px;
          //   margin-top: 34px;
          //   margin-bottom: 17px;
          //   // background: rgba(3, 24, 49, 0.5);
          //   // border: 1px solid #009aff;
          //   ::v-deep .el-input {
          //     //   width: 176px;
          //     //   height: 36px;
          //     background: rgba(3, 24, 49, 0.5);
          //     //   border: 1px solid #009aff;
          //   }
          //   ::v-deep .el-select .el-input__inner {
          //     width: 176px;
          //     height: 36px;
          //     background: rgba(3, 24, 49, 0.5);
          //     font-size: 16px;
          //     font-family: PingFangSC-Regular, PingFang SC;
          //     font-weight: 400;
          //     color: #9fc4ff;
          //     line-height: 22px;
          //     border: 1px solid #009aff;
          //   }
          // }
          // .table-list {
          //   ::v-deep .el-table .cell {
          //     text-align: center;
          //     color: #93b7f1;
          //   }
          //   /*最外层透明*/
          //   ::v-deep .el-table,
          //   ::v-deep .el-table__expanded-cell {
          //     background-color: transparent;
          //     border: none;
          //   }
          //   /* 删除表格下最底层的横线 */
          //   // ::v-deep .el-table::before {
          //   //   left: 0;
          //   //   bottom: 0;
          //   //   width: 100%;
          //   //   height: 0px;
          //   // }

          //   /* 表格内背景颜色 */
          //   ::v-deep .el-table th,
          //   ::v-deep .el-table tr,
          //   ::v-deep .el-table td {
          //     background-color: transparent;
          //     border: 1px dashed #ccc;
          //   }
          // }
        }
      }
    }
  }
}
</style>
