<template>
  <div class="top">
    <div class="centen">
        <div class="m-timeline-wrap">
    <div class="m-time-dot">
      <div
        :class="['m-dot-box', {'active': active===item.flightSortieId}]"
        @click="onClickYear(item)"
        v-for="(item, index) in timelineData"
        :key="index">
        <p class="u-year-top">{{ item.flightSortieTime }}</p>
        <div class="m-dot">
          <div class="u-dot"></div>
        </div>
        <p class="u-year-bottom">{{ item.taskName }}</p>
      </div>
    </div>
  </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'HorizonTimeLine',
  props: {
    timelineData: { // 时间轴数据
      type: Array,
      default: () => []
    },
    active: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onClickYear (data) {
      this.$emit('upactive', data.flightSortieId)
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.top {
    height: 100px;
    overflow-x: hidden;
}
.m-timeline-wrap {
  margin-top: 50px;
  width: 1060px;
  height: 3px;
  background: rgba(0, 154, 255, 0.5000);
  .m-time-dot {
    display: flex;
    justify-content: space-between;
    .m-dot-box {
      cursor: pointer;
      text-align: center;
      margin-top: -33px;
      .u-year {
        &-top, &-bottom {
          font-size: 16px;
font-family: D-DIN, D;
font-weight: normal;
color: #9FC4FF;
line-height: 17px;
        }
        

      }
      .m-dot {
        margin: 0 auto;
        margin-top: 10px;
        width: 14px;
        height: 14px;
        background: #8dc6f5;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        .u-dot {
          width: 14px;
          height: 14px;
          background: rgba(15, 239, 128, 1);
          border-radius: 50%;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .m-dot-box:hover {
      .m-dot {
        .u-dot {
          background: rgba(15, 239, 128, 0.8);
        }
      }
    }
    .active {
      // .u-year-top, 
      .u-year-bottom {
        color: rgba(15, 239, 128, 0.8);
      }
    }
  }
}
</style>