<template>
  <div class="page-video">
    <div class="video-box" id="mapCom"></div>
    <div class="video-box-footer" v-if="!isShowFooter">
      <div class="slider-left">{{ cj02List[0] && cj02List[0].time }}</div>
      <div class="video-box-footer-slider">
        <el-slider
          :min="0"
          :disabled="!isShow"
          :max="cj02List.length ? cj02List.length - 1 : 0"
          :format-tooltip="formatTooltip"
          @change="handleChange"
          v-model="moment"
        >
        </el-slider>
      </div>
      <div class="slider-right">{{ cj02List[cj02List.length - 1] && cj02List[cj02List.length - 1].time }}</div>
      <div class="play-box" v-show="isShow">
        <i class="el-icon-video-play" v-show="!play" @click="$emit('play', true)"></i>
        <i class="el-icon-video-pause" v-show="play" @click="$emit('play', false)"></i>
      </div>
      <div class="dropdown" v-show="isShow">
        <el-dropdown @command="toogleSpeed($event, true)">
          <span class="el-dropdown-link">
            {{speedText}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in stepNumOptions" :key="index" :command="item">{{item}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { wgs84togcj02 } from '@/lib/cesium/utils'
export default {
  name: "mapCom",
  props: {
    recordList: {
      type: Array,
      default: () => ([])
    },
    isShow: {
      type: Boolean,
    },
    isShowFooter: {
      type: Boolean,
      default: () => false
    },
    play: {
      type: Boolean,
    },
    changeTime: {
      type: Number,
    },
    speed: {
      type: Number,
    },
    track: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mapCom: null,
      name: "",
      line: '',
      oldLine: '',

      cj02List: [],
      // play: false,
      position: [39.90923, 116.397428], // 开始位置，也是地图的中心点
      stepTime: 5, //一倍就是 5s
      stepNum: 1, //播放倍数
      stepNumOptions: [0.5, 1, 2, 3],

      moment: 0, // 时间
      pathList: [
        [41, 123], // 实际上正在走的轨迹
        [39, 121],
      ],
    //   disablePathList: [
    //     // 默认轨迹
    //     [41, 123],
    //     [39, 121],
    //     [41, 126],
    //   ],
      disablePathList: [],
      // play: false, //是否正在播放轨迹
      playInterval: null,
      breakTimeInterval: null,
      marker: '',
      recordListMap: [],
      speedText: '1x'
    };
  },
  
  watch: {
    "isShow": {
      handler (value) {
        if (value) {
            this.init(this.recordList,value)
        } else {
            this.init(this.recordList,value)
        }

      },
      deep: true,
      // immediate: true
    },
    "recordList": {
      handler (value, oldValue) {
        if(value) {
          this.recordListMap = value
        } else {
        this.recordListMap = []
        }
        
        // this.init(value)
      },
      deep: true,
      // immediate: true
    },
    "moment": {
      handler (value) {
        console.log('moment', value);
        let { cj02List } = this,
          record = cj02List[value]

        if (cj02List && cj02List.length && this.track) {
          let pathList = cj02List.slice(0, value + 1) || undefined
          this.$emit('currentList', pathList[pathList.length-1])
          if (pathList && pathList.length) {
            this.pathList = [...pathList.filter(item => (item.lng && item.lat)).map(item => [item.lat, item.lng])]
            
            if (this.line) {
                this.mapCom.removeLayer(this.line)
            }
            this.line = L.polyline(this.pathList, { color: "green" }).addTo(this.mapCom);
            let last = this.pathList.pop();
            this.position = last
            if (this.marker) {
                this.mapCom.removeLayer(this.marker)
            }
            if (this.isShow) {
              this.marker = L.marker(last).addTo(this.mapCom);
            }
            
          }
          // if (record) {
          //   this.updateMarkerPos([ record.lat,record.lng])
          // }
        }
      },
      // immediate: true
    },
    "play": {
      handler (value) {
        if (value) {
          this.handlePlay()
        } else {
          this.handlePause()
        }
        
      },
      deep: true,
      immediate: true
    },
    "changeTime": {
      handler (value) {
        if (value) {
          this.moment = value
        }
      },
      deep: true,
      immediate: true
    },
    "speed": {
      handler (value) {
        if (value) {
          this.toogleSpeed(value, false)
        }
      },
      deep: true,
      immediate: true
    },
    "track": {
      handler(value) {
        if(value) {

        } else {
          this.moment = 0
          this.mapCom.removeLayer(this.oldLine)
          this.mapCom.removeLayer(this.line)
          this.mapCom.removeLayer(this.marker)
        }
      }
    }
  },
  computed: {
    /**
     * 当前记录
     */
    record () {
      let { moment, cj02List } = this
      return cj02List[moment] || {}
    },

    stepNumTime () {
      return parseInt((this.stepNum * this.stepTime))
    },
  },
  methods: {
    initParams () {
      this.moment = 0
      this.pathList = []
      this.stepTime = 5
      this.stepNum = 1
    },
    init(list, type) {
        // this.map = L.map('map').setView(this.position, 7);
      this.initParams()
      if (type) {
        if (list && list.length) {
        let cj02List = this.cj02List = list.map(item => {
          let cj02Pos = wgs84togcj02(item.longitude, item.latitude)
          return {
            ...item,
            lng: cj02Pos[0],
            lat: cj02Pos[1],
          }
        })
        if (cj02List && cj02List.length) {
          this.disablePathList = [...this.cj02List.map(item => [item.lat,item.lng])]
          let first = cj02List[0],
           last = cj02List[cj02List.length - 1]
          let position = [first.lat,first.lng]
          this.position = position
        }
          
        this.mapCom.flyTo(this.position, 7);
       }
      if (this.position.length) {
      //   this.map = L.map("map", {
      //   center: this.position, // 地图中心
      //   zoom: 7, //缩放比列
      //   zoomControl: false, //禁用 + - 按钮
      //   doubleClickZoom: false, // 禁用双击放大
      //   attributionControl: false, // 移除右下角leaflet标识
      // });
      // this.name = L.tileLayer(
      //   "http://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}",
      //   {
      //     maxZoom: 20,
      //   }
      // ).addTo(this.map);
      this.marker= L.marker(this.position).addTo(this.mapCom);
      if (this.disablePathList.length) {
        this.oldLine = L.polyline(this.disablePathList, { color: "red" }).addTo(this.mapCom);
        
        let bounds=L.latLngBounds(this.disablePathList)
        //定位到矩形
        this.mapCom.fitBounds(bounds);
        // this.mapCom.flyToBounds(polyline.getBounds());
      }
      
      }
      } else {
        this.$emit('play', false)
        if (this.line) {
          this.mapCom.removeLayer(this.line)
        }
        if (this.oldLine) {
          this.mapCom.removeLayer(this.oldLine)
        }
        if (this.marker) {
          this.mapCom.removeLayer(this.marker)
        }
        this.moment = 0
        if (this.position.length) {
          // 判断是否已经创建地图
          if(!this.mapCom) {
            this.mapCom = L.map("mapCom", {
              center: this.position, // 地图中心
              zoom: 7, //缩放比列
              zoomControl: false, //禁用 + - 按钮
              doubleClickZoom: false, // 禁用双击放大
              attributionControl: false, // 移除右下角leaflet标识
            });
          }
        
      this.name = L.tileLayer(
        "http://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
        }
      ).addTo(this.mapCom);
      }
      }
    },
    
    formatTooltip(value) {
      let record = this.cj02List[value];
      if (record) {
        // return record.dateTime;
        return record.time;
      } else {
        return "0000-00-00 00:00:00";
      }
    },
    /**
     * 播放
     */
    handlePlay () {
      this.play = true
      
      window.clearInterval(this.playInterval)
      let _interval = this.playInterval = window.setInterval(() => {
        let index = this.moment + 1
        if (index > this.cj02List.length - 1) {
          this.handlePause()
          this.$emit('play', false)
          this.$message({
            type: "success",
            message: "播放完毕",
            duration: 1000,
            onClose: () => {
            //   this.moment = 0
            }
          })
        } else {
          this.moment = index
        }
      }, 1000 / this.stepNum)
    },

    /**
     * 暂停
     */
    handlePause () {
      this.play = false
    //   this.$emit('play', this.play)
      window.clearInterval(this.playInterval)
      this.playInterval = null
    },

    /**
     * 快进
     */
    handleForward () {
      this.breakTime(1)
    },
    /**
     * 快退
     */
    handleRewind () {
      this.breakTime(-1)
    },

    breakTime (type) {
      if (this.breakTimeInterval) {
        window.clearInterval(this.breakTimeInterval)
      }

      let { stepNumTime, cj02List } = this
      let time = Number(stepNumTime),
        count = 0,
        min = 0,
        max = cj02List.length - 1

      this.breakTimeInterval = window.setInterval(() => {
        count += 1
        if ((this.moment + 1 * type) < min) {
          this.moment = min
          window.clearInterval(this.breakTimeInterval)
          return false
        }
        if ((this.moment + 1 * type) > max) {
          this.moment = max
          this.$message({
            type: "success",
            message: "播放完毕",
            duration: 1000,
            onClose: () => {
              this.handlePause()
              this.moment = min
            }
          })
          window.clearInterval(this.breakTimeInterval)
          return false
        }
        if (count >= time) {
          window.clearInterval(this.breakTimeInterval)
          return false
        }
        this.moment += 1 * type
      }, 15)
    },
    // 拖动进度条
    handleChange(e) {
        this.$emit('isChangeMapTime', parseInt(e))
    },
    // 选择倍速
    toogleSpeed(e, isEmit) {
        if (isEmit) {
            this.$emit('isChangeMapSpeed', parseInt(e))
        }
      this.stepNum = e
      this.speedText = e + 'x';
      let play = this.play
      this.handlePause()
      if (play) {
        this.handlePlay()
      }
    }
  },
  mounted() {
    this.init();
  },
  destroyed() {
    //离开路由之后移除图层
    this.mapCom.removeLayer(this.name);
  },
};
</script>

<style lang="scss" scoped>
.page-video {
  width: 100%;
  height: 100%;
//   margin-top: 50px;
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: center;
  position: relative;
  .video-box {
     width: 100%;
     height: 100%;
    // width: 1084px;
    // height: 720px;
    margin: 0 auto;
    z-index: 1;
  }
  .video-box-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    // background-color: #072A61;
    background: url(~@/assets/flylog/footer-box.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 150px;
    z-index: 2;
    display: flex;
    flex-flow: column;
    // justify-items: center;
    align-items: center;
    .video-box-footer-slider {
      margin-top: 5px;
      width: 80%;
      position: relative;
    }
    .slider-left {
      position: absolute;
      top: 40px;
      left: 35px;
      color: #fff;
    }
    .slider-right {
      position: absolute;
      top: 40px;
      right: 35px;
      color: #fff;
    }
    .play-box {
        position: absolute;
        top: 10px;
        left: 25px;
        cursor: pointer;
      color: #fff;
      font-size: 26px;
      i {
        padding: 0 8px;
      }
    }
    .play-buttom-box {
      cursor: pointer;
      color: #fff;
      font-size: 26px;
      i {
        padding: 0 8px;
      }
    }
    .dropdown {
      position: absolute;
      top: 13px;
      right: 35px;
      cursor: pointer;
      color: #fff;
      .el-dropdown-link {
        cursor: pointer;
        color: #fff;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}
</style>