<template>
  <div>
    <div class="task-box">
      <div class="task">
        <div class="task-unit">pcs</div>
        <div class="task-value">{{ countList.taskCount || 0 }}</div>
        <div class="task-title">总任务数量</div>
      </div>
      <div class="task-line">
        <img src="../../../../assets/flylog/task-line.png" alt="" />
      </div>
      <div class="task">
        <div class="task-unit">次</div>
        <div class="task-value">{{ countList.flightCount }}</div>
        <div class="task-title">总飞行架次</div>
      </div>
      <div class="task-line">
        <img src="../../../../assets/flylog/task-line.png" alt="" />
      </div>
      <div class="task">
        <div class="task-unit">次</div>
        <div class="task-value">{{ countList.flightDistance }}</div>
        <div class="task-title">总飞行里程</div>
      </div>
      <div class="task-line">
        <img src="../../../../assets/flylog/task-line.png" alt="" />
      </div>
      <div class="task">
        <div class="task-unit">h</div>
        <div class="task-value">{{ countList.flightTime }}</div>
        <div class="task-title">总飞行时长</div>
      </div>
      <div class="task-line">
        <img src="../../../../assets/flylog/task-line.png" alt="" />
      </div>
      <div class="task">
        <div class="task-unit">pcs</div>
        <div class="task-value">{{ countList.imageCount }}</div>
        <div class="task-title">总照片数量</div>
      </div>
      <div class="task-line">
        <img src="../../../../assets/flylog/task-line.png" alt="" />
      </div>
      <div class="task">
        <div class="task-unit">次</div>
        <div class="task-value">{{ countList.warningCount }}</div>
        <div class="task-title">ai预警总次数</div>
      </div>
    </div>
    <div class="select-task">
      <el-select v-model="taskValue" placeholder="任务名称" size="mini" @change="changge" clearable>
        <el-option
          v-for="item in taskList"
          :key="item.id"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>
    </div>
    <div class="table-list">
      <Table
        class="task-table"
        :columns="columns"
        :dataSource="tableData"
        />
    </div>
  </div>
</template>

<script>
import Table from "@/components/common/table";
export default {
    components: {
    Table,
  },
  props: {
    countList: {
      type: Object,
      default: () => {},
    },
    optionsTask: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    deviceHardId: {
        type: Number,
        default: null
    },
    taskName: {
      type: String,
      default: null
    },
    taskList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
        columns: [
        { prop: "flightSortieName", label: "飞行架次号(次)" },
        { prop: "taskName", label: "任务名称"},
        { prop: "flightDistance", label: "飞行里程数(km)",width: '150px' },
        { prop: "flightSortieTime", label: "飞行时长", width: '100px' },
        { prop: "imageCount", label: "取证上传图片数", width: '100px' },
        { prop: "warningCount", label: "AI预警数", width: '100px' },
        { prop: "playback", label: "历史记录回放" },
        ],
        taskValue: null, // 选中的任务名称

    }
  },
  methods: {
    changge () {
      this.$emit('untaskName', this.taskValue)
    }
  }
  
};
</script>

<style lang="scss" scoped>
.task-box {
  width: 100%;
  height: 186px;
  // margin-left: 20px;
  display: flex;
  justify-content: center;
  .task {
    width: 112px;
    height: 186px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .task-unit {
      width: 112px;
      height: 112px;
      background: url(~@/assets/flylog/unit-bg.png) no-repeat;
      background-size: 112px 112px;
      text-align: center;
      line-height: 112px;
      font-family: WeiRuanYaHei-Regular;
      font-size: 30px;
      color: #4bc5ff;
    }
    .task-value {
      // width: 56px;
      height: 30px;
      font-size: 30px;
      font-family: D-DIN-Bold, D-DIN;
      font-weight: bold;
      color: #a2c4fd;
      line-height: 33px;
      text-align: center;
      margin-top: 14px;
    }
    .task-title {
      // width: 80px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6a99e9;
      line-height: 22px;
      margin-top: 8px;
    }
  }
  .task-line {
    width: 12px;
    height: 102px;
    margin: 0px 64px;
    img {
      width: 12px;
      height: 102px;
    }
  }
}
.select-task {
  width: 176px;
  height: 36px;
  margin-top: 34px;
  margin-bottom: 17px;
  // background: rgba(3, 24, 49, 0.5);
  // border: 1px solid #009aff;
  ::v-deep .el-input {
    //   width: 176px;
    //   height: 36px;
    background: rgba(3, 24, 49, 0.5);
    //   border: 1px solid #009aff;
  }
  ::v-deep .el-select .el-input__inner {
    width: 176px;
    height: 36px;
    background: rgba(3, 24, 49, 0.5);
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9fc4ff;
    line-height: 22px;
    border: 1px solid #009aff;
  }
}
.table-list {
  height: 520px;
  ::v-deep .el-table .cell {
    text-align: center;
    color: #93b7f1;
  }
  /*最外层透明*/
  ::v-deep .el-table,
  ::v-deep .el-table__expanded-cell {
    background-color: transparent;
    border: none;
  }
  /* 删除表格下最底层的横线 */
  // ::v-deep .el-table::before {
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 0px;
  // }

  /* 表格内背景颜色 */
  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table td {
    background-color: transparent;
    border: 1px dashed #ccc;
  }
}
</style>